import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SeoComponent from "../components/seo"

const References = () => (
  <Layout>
    <SeoComponent title="References" />
    <div className="aux-page">
      <div className="container text-center">
        <h1 className="font-weight-bold text-white">COGCC</h1>
        <p className="text-white">Cleanup Concentrations</p>
      </div>
    </div>
    <div className="container" style={{ padding: "100px 0" }}>
      <table className="tg">
        <thead>
          <tr>
            <th className="tg-0pky">
              <span className="font-weight-bold">Contaminant of Concern</span>
            </th>
            <th className="tg-0lax">
              <span className="font-weight-bold">Concentrations</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tg-0pky">
              Soil TPH (total volatile [C₆-C₁₀] and extractable [C₁₀-C₃₆]
              hydrocarbons)
            </td>
            <td className="tg-0lax">500mg/kg</td>
          </tr>
          <tr>
            <td className="tg-0lax">
              Soils and Groundwater - liquid hydrocarbons including condensate
              and oil.
            </td>
            <td className="tg-0lax">below visual detection limits</td>
          </tr>
          <tr>
            <td className="tg-0lax " colspan="2">
              <span className="font-weight-bold">
                Soil Suitability for Reclamation
              </span>
            </td>
          </tr>
          <tr>
            <td className="tg-0lax">
              Electrical conductivity (EC) (by saturated paste method)¹ ²
            </td>
            <td className="tg-0lax">&lt;mmhos/cm</td>
          </tr>
          <tr>
            <td className="tg-0lax">
              Sodium absorption ratio (SAR) (by saturated paste method)¹ ² ³
            </td>
            <td className="tg-0lax">&lt;6</td>
          </tr>
          <tr>
            <td className="tg-0lax">pH (by saturated paste method)¹ ²</td>
            <td className="tg-0lax">2mg/l</td>
          </tr>
          <tr>
            <td className="tg-0lax" colspan="2">
              <span className="font-weight-bold">
                Organic Compounds in Groundwater ⁴
              </span>
            </td>
          </tr>
          <tr>
            <td className="tg-0lax">Benzne</td>
            <td className="tg-0lax">5μg/l</td>
          </tr>
          <tr>
            <td className="tg-0lax">Toluene⁵</td>
            <td className="tg-0lax">560 to 1,000μg/l</td>
          </tr>
          <tr>
            <td className="tg-0lax">ethylbenzene</td>
            <td className="tg-0lax">700μg/l</td>
          </tr>
          <tr>
            <td className="tg-0lax">
              xylenes (sum of o-, m-, and p- isomers = total xyelenes)⁵
            </td>
            <td className="tg-0lax">1,400 to 10,000μg/l</td>
          </tr>
          <tr>
            <td className="tg-0lax">naphthalene</td>
            <td className="tg-0lax">140μg/l</td>
          </tr>
          <tr>
            <td className="tg-0lax">1,2,4-trimethylbenzene</td>
            <td className="tg-0lax">67μg/l</td>
          </tr>
          <tr>
            <td className="tg-0lax">1,3,5-trimethylbenzene</td>
            <td className="tg-0lax">67μg/l</td>
          </tr>
          <tr>
            <td className="tg-0lax" colspan="2">
              <span className="font-weight-bold">
                Groundwater Inorganic Parameters⁴
              </span>
            </td>
          </tr>
          <tr>
            <td className="tg-0lax">Total dissolved solids (TDS)¹</td>
            <td className="tg-0lax">&lt;1.25 X local background</td>
          </tr>
          <tr>
            <td className="tg-0lax">Chloride ion¹</td>
            <td className="tg-0lax">250mg/l or &lt;1.25 X local background</td>
          </tr>
          <tr>
            <td className="tg-0lax">Sulfate ion¹</td>
            <td className="tg-0lax">250mg/l or &lt;1.25 X local background</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
)

export default References
